import React from "react";
import Loading from "./components/atoms/Loading";
import PaymentConfirm from "./components/templates/PaymentConfirm";
import PaymentForm from "./components/templates/PaymentForm";
import { useAlert } from "react-alert";
import PaymentSuccess from "./components/templates/PaymentSuccess";

// ネイティブ側で用意しているメソッド呼び出しのハンドラー
interface NativeHandler {
  postMessage(message: string): void;
}
declare var PayScreen: NativeHandler;

const params = {
  headers: { "Content-Type": "application/json; charset=utf-8" },
};
function App() {
  const alert = useAlert();
  const [state, setState] = React.useState({
    ccYear: "",
    ccMonth: "",
    securityCode: "",
    ccNumber: "",
    //above is value form info payment
    step: 1,
    //above is step payment
    amount: 0,
    billing_id: 0,
    to: "",
    payee: "",
    merchant_id: "",
    service_id: "",
    title: "",
    token: "",
    tokenKey: "",
    error: "",
    maskedCcNumber: "",
  });
  const url = window.location.href;
  const searchParams = new URLSearchParams(new URL(url).search);
  const token_code = url.split("/").pop();
  const isTypePc = searchParams.get("type") === "pc";

  React.useEffect(() => {
    (async () => {
      try {
        let url = `${
          process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ""
        }/api/v1/payments/${token_code}`;
        await fetch(url, { ...params, method: "get" }).then(async (data) => {
          if (data.ok) {
            const res = await data?.clone().json();
            setState((s) => ({ ...s, ...res }));
          } else {
            const res = await data?.clone().json();
            if (res?.message) {
              alert.show(res.message, {
                onClose: () => {
                  if (searchParams.get('type') === 'pc') {
                    return window.location.replace(
                      `${process.env.REACT_APP_WEB_URL ? process.env.REACT_APP_WEB_URL : ''}bills`,
                    );
                  }
                }
              });
            } else {
              alert.show("有効期限が切れています");
            }
          }
        });
      } catch (error) {
        alert.show("ネットワークエラー");
      }
    })();
  }, [alert, token_code]);

  const afterGenerateToken = React.useCallback((response: TokenResponse) => {
    if (response.result === "OK") {
      setState((s) => ({ ...s, ...response.tokenResponse }));
    } else {
      setState((s) => ({ ...s, error: response.errorCode }));
    }
  }, []);

  const confirmFunc = React.useCallback(
    (args: {
      ccNumber: string;
      ccExpiration: string;
      securityCode: string;
    }) => {
      setState((s) => ({ ...s, error: "" }));
      com_sbps_system.generateToken(
        {
          ...args,
          merchantId: state.merchant_id,
          serviceId: state.service_id,
        },
        afterGenerateToken
      );
    },
    [state.merchant_id, state.service_id, afterGenerateToken]
  );

  const submitFunc = React.useCallback(() => {
    (async () => {
      try {
        let url = `${
          process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : ""
        }/api/v1/payments/${token_code}`;
        await fetch(url, {
          ...params,
          method: "put",
          body: JSON.stringify({
            billing_id: state.billing_id,
            token: state.token,
            tokenKey: state.tokenKey,
            maskedCcNumber: state.maskedCcNumber,
          }),
        }).then(async (data) => {
          if (data.ok) {
            setState((s) => ({ ...s, step: 3 }))
          } else {
            const res = await data?.clone().json();
            alert.show(res?.message || "決済に失敗しました", {
              onClose: () =>
                setState((s) => ({ ...s, token: "", tokenKey: "" })),
            });
          }
        });
      } catch (error) {
        console.log(error);
        alert.show("ネットワークエラー", {
          onClose: () => setState((s) => ({ ...s, token: "", tokenKey: "" })),
        });
      }
    })();
  }, [
    token_code,
    state.token,
    state.tokenKey,
    state.billing_id,
    state.maskedCcNumber,
    alert,
  ]);

  const handleBackBillDetail = (action: string) => {
    if (searchParams.get("type") === "pc") {
      return window.location.replace(
        `${
          process.env.REACT_APP_WEB_URL
            ? process.env.REACT_APP_WEB_URL
            : ""
        }bills/${state.billing_id}`
      );
    } else {
      PayScreen.postMessage(action);
    }
  }

  const handleBackFormPayment = () => {
    setState((s) => ({ ...s, token: "", tokenKey: "" }));
  }

  if(state.step === 3) {
    return <PaymentSuccess isTypePc={isTypePc} onBackBillDetail={handleBackBillDetail}/>
  }

  return state.to ? (
    state.tokenKey ? (
      <PaymentConfirm
        isTypePc={isTypePc}
        to={state.to}
        amount={state.amount}
        payee={state.payee}
        title={state.title}
        maskedCcNumber={state.maskedCcNumber}
        onBackFormPayment={handleBackFormPayment}
        submit={submitFunc}
      />
    ) : (
      <PaymentForm
        isTypePc={isTypePc}
        handleBackBillDetail={handleBackBillDetail}
        error={state.error}
        title={state.title}
        ccYear={state.ccYear}
        ccMonth={state.ccMonth}
        securityCode={state.securityCode}
        ccNumber={state.ccNumber}
        setValueForm={setState}
        submit={confirmFunc}
      />
    )
  ) : (
    <Loading />
  );
}
export default App;
