import React from "react";
import styles from "./LabeledInputField.module.scss";

export interface LabeledInputFieldProps {
  label: string;
  value: string;
  identity: string;
  type: string;
  maxLength?: number;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  onChange: (
    e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

export const LabeledInputField: React.FC<LabeledInputFieldProps> = ({
  label,
  value,
  identity,
  placeholder,
  type = "text",
  maxLength,
  disabled,
  error,
  onChange,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.box + " " + (disabled ? styles.disabledBox : "") + (error ? styles.error : "")}>
        <div className={styles.inner}>
          <input
            type={type}
            name={identity}
            disabled={!!disabled}
            autoComplete={identity}
            placeholder={placeholder}
            maxLength={maxLength}
            value={value}
            onChange={(e) => {
              onChange(e);
            }}
          />
          <div
            className={`
              ${styles.string}
              ${styles.selected}
              ${disabled ? styles.disabled : ""}
            `}
          >
            <span>{label}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LabeledInputField;
