import React from "react";
import styles from "./HeadLine.module.scss";

export interface HeadLineProps {
  text: string;
}

export const HeadLine: React.FC<HeadLineProps> = ({ text }) => (
  <div className={styles.headLine}>
    <span>{text}</span>
  </div>
);
export default HeadLine;
