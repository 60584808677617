import React from "react";
import HeadLine from "../../atoms/HeadLine";
import LabeledInputField from "../../atoms/LabeledInputField";
import SubmitButton from "../../atoms/SubmitButton";
import styles from "./PaymentForm.module.scss";
import Stepper from "../../atoms/Stepper";
import BtnBack from "../../atoms/BtnBack";

export interface PaymentFormProps {
  title: string;
  error?: string;
  ccYear: string;
  ccMonth: string,
  securityCode: string,
  ccNumber: string,
  isTypePc: boolean;
  setValueForm: (params: any) => void;
  handleBackBillDetail: (action: string) => void;
  submit: (args: {
    ccNumber: string;
    ccExpiration: string;
    securityCode: string;
  }) => void;
}

const validates: { [key: string]: (value: any) => string } = {};
const NUMBER_REGEX = /^[0-9\b]+$/;

validates["ccNumber"] = (val) => {
  return val.ccNumber.length < 14 ? "正しいカード番号を入力してください。" : "";
};
validates["securityCode"] = (val) => {
  return val.securityCode.length < 3
    ? "正しいセキュリティコードを入力してください。"
    : "";
};
validates["ccYear"] = (val) => {
  return Number(`20${val.ccYear}`) < new Date().getFullYear()
    ? "正しい有効年を入力してください。"
    : "";
};
validates["ccMonth"] = (val) => {
  return isNaN(Number(val.ccMonth)) ||
    Number(val.ccMonth) < 1 ||
    Number(val.ccMonth) > 12
    ? "正しい有効月を入力してください。"
    : "";
};

export const PaymentForm: React.FC<PaymentFormProps> = (props) => {
  const [state, setState] = React.useState({
    isSubmitted: false,
    isSubmitting: false,
    isConfirm: false,
    errorMessages: {} as { [key: string]: string },
  });
  React.useEffect(() => {
    if (props.error) {
      window.scrollTo(0, 0);
      if (props.error.match(/003$/)) {
        setState((s) => ({
          ...s,
          isSubmitting: false,
          isSubmitted: true,
          errorMessages: {
            ...s.errorMessages,
            ccNumber: "クレジットカード番号が正しくありません",
          },
        }));
      } else if (props.error.match(/004$/)) {
        setState((s) => ({
          ...s,
          isSubmitting: false,
          isSubmitted: true,
          errorMessages: {
            ...s.errorMessages,
            ccYear: "有効期限が正しくありません",
          },
        }));
      } else if (props.error.match(/005$/)) {
        setState((s) => ({
          ...s,
          isSubmitting: false,
          isSubmitted: true,
          errorMessages: {
            ...s.errorMessages,
            securityCode: "セキュリティコードが正しくありません",
          },
        }));
      } else {
        setState((s) => ({
          ...s,
          isSubmitting: false,
          isSubmitted: true,
          errorMessages: {
            ...s.errorMessages,
            ccNumber: `エラーコード: ${props.error}`,
          },
        }));
      }
    }
  }, [props.error]);

  const onSubmit = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      const errorMessages = {} as { [key: string]: string };
      Object.keys(validates).forEach((key) => {
        const ret = validates[key]({
          ccYear: props.ccYear,
          ccMonth: props.ccMonth,
          securityCode: props.securityCode,
          ccNumber: props.ccNumber,
        });
        if (ret) {
          errorMessages[key] = ret;
        }
      });
      if (Object.keys(errorMessages).length > 0) {
        setState((s) => ({ ...s, isSubmitted: true, errorMessages }));
        return;
      }
      setState((s) => ({ ...s, isSubmitting: true, errorMessages: {} }));
      props.submit({
        ccExpiration: `20${props.ccYear}${("000" + props.ccMonth).slice(-2)}`,
        securityCode: props.securityCode,
        ccNumber: props.ccNumber,
      });
    },
    [state, props]
  );

  const onChange = React.useCallback(
    (e: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      e.preventDefault();
      const val = e.currentTarget.value;
      const key = e.currentTarget.name;
      const args = { [key]: val };
      const ret = validates[key] ? validates[key](args) : "";

    //value must be number
    if (val === '' || NUMBER_REGEX.test(val)) {
      props.setValueForm((prevState: any) => ({
        ...prevState,
          [key]: val,
      }))

      setState((prevState) => ({
        ...prevState,
        errorMessages: { ...prevState.errorMessages, [key]: ret },
      }));
    }
  }, []);

  return (
    <div className={styles.page}>
      {props.isTypePc && <Stepper activeStep={1}/>}
      <form>
      {props.isTypePc && <BtnBack onClick={() => props.handleBackBillDetail("backToBillDetail")}/>}
        <div className={styles.innerForm}>
          <HeadLine text={props.title} />
          <div className={styles.message}>カード情報を入力してください</div>
          <div className={styles.fieldWrapper}>
            <div className={styles.number}>
              <LabeledInputField
                label="カード番号"
                identity="ccNumber"
                type="tel"
                maxLength={16}
                value={props.ccNumber}
                placeholder="(例)1234123412341234"
                onChange={onChange}
                error={Boolean(state.isSubmitted && state.errorMessages["ccNumber"])}
              />
            </div>
          </div>
          {state.isSubmitted && state.errorMessages["ccNumber"] && (
            <div className={styles.errorField}>
              {state.errorMessages["ccNumber"]}
            </div>
          )}
          <div className={styles.subMessage}>※入力は半角数字のみ (ハイフン、スペースなし)</div>
          <div className={styles.message}>有効期限</div>
          <div className={styles.fieldWrapper}>
            <div className={styles.month}>
              <LabeledInputField
                label="月"
                identity="ccMonth"
                type="tel"
                maxLength={2}
                value={props.ccMonth}
                placeholder="(例)1"
                onChange={onChange}
                error={Boolean(state.isSubmitted && state.errorMessages["ccMonth"])}
              />
            </div>
            <div className={styles.unit}>月</div>
            <div className={styles.year}>
              <LabeledInputField
                label="年"
                identity="ccYear"
                type="tel"
                maxLength={2}
                value={props.ccYear}
                placeholder="(例)25"
                onChange={onChange}
                error={Boolean(state.isSubmitted && state.errorMessages["ccYear"])}
              />
            </div>
            <div className={styles.unit}>年</div>
          </div>
          {state.isSubmitted &&
            (state.errorMessages["ccMonth"] || state.errorMessages["ccYear"]) && (
              <>
                <div className={styles.errorField}>{state.errorMessages["ccMonth"] || ""}</div>
                <div className={styles.errorField}>{state.errorMessages["ccYear"] || ""}</div>
              </>
            )}
          <div className={styles.subMessage}>※入力は半角数字のみ</div>
          <div className={styles.fieldWrapper}>
            <div className={styles.code}>
              <LabeledInputField
                label="セキュリティコード"
                identity="securityCode"
                type="tel"
                maxLength={4}
                value={props.securityCode}
                placeholder="(例)2222"
                onChange={onChange}
                error={Boolean(state.isSubmitted && state.errorMessages["securityCode"])}
              />
            </div>
          </div>
          {state.isSubmitted && state.errorMessages["securityCode"] && (
            <div className={styles.errorField}>
              {state.errorMessages["securityCode"]}
            </div>
          )}
          <div className={styles.subMessage}>
            <div>※セキュリティコードはカードに記載されている3桁または4桁の数字です。</div>
            <div>入力は半角数字のみ</div>
          </div>
          <SubmitButton
            className={styles.btnSubmit}
            text="確認"
            isSubmitting={state.isSubmitting}
            onClick={onSubmit}
          />
        </div>
      </form>
    </div>
  );
};

export default PaymentForm;
