import React from "react";
import SubmitButton from "../../atoms/SubmitButton";
import styles from "./PaymentConfirm.module.scss";
import Stepper from "../../atoms/Stepper";
import BtnBack from "../../atoms/BtnBack";
import HeadLine from "../../atoms/HeadLine";

export interface PaymentConfirmProps {
  amount: number;
  to: string;
  payee: string;
  title: string;
  maskedCcNumber: string;
  isTypePc: boolean;
  onBackFormPayment: () => void;
  submit: () => void;
}

const displayToday = () => {
  const today = new Date();
  return `${today.getFullYear()}/${String(today.getMonth() + 1).padStart(2, '0')}/${String(today.getDate()).padStart(2, '0')}`;
};
export const PaymentConfirm: React.FC<PaymentConfirmProps> = (props) => {
  const submit = props.submit;
  const onSubmit = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      setState((s) => ({ ...s, isSubmitting: true }));
      submit();
    },
    [submit]
  );
  const [state, setState] = React.useState({
    isSubmitting: false,
  });

  return (
    <div className={styles.page}>
      {props.isTypePc && <Stepper activeStep={2}/>}
      <div className={styles.wrapper}>
        {props.isTypePc && <div className={styles.head}>
          <BtnBack onClick={props.onBackFormPayment}/>
        </div>}
        <HeadLine text={props.title} />
        <div className={styles.row}>
          <div className={styles.title}>支払い先</div>
          <div className={styles.value}>{props.to}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>支払者</div>
          <div className={styles.value}>{props.payee}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>支払日</div>
          <div className={styles.value}>{displayToday()}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>支払い方法</div>
          <div className={styles.value}>クレジットカード</div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>カード番号</div>
          <div className={styles.value}>{props.maskedCcNumber}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>金額</div>
          <div className={styles.value}>
            {props.amount.toLocaleString("ja-JP", {
              style: "currency",
              currency: "JPY",
            })}
          </div>
        </div>
        <SubmitButton
          text="支払い確定"
          className={styles.btnSubmit}
          isSubmitting={state.isSubmitting}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default PaymentConfirm;
