import React from "react";
import styles from "./PaymentSuccess.module.scss";
import Stepper from "../../atoms/Stepper";
import CheckDone from "../../../svg/check-done.svg"
import SubmitButton from "../../atoms/SubmitButton";

type PaymentSuccessProps = {
  isTypePc: boolean;
  onBackBillDetail: (action: string) => void;
}

export const PaymentSuccess: React.FC<PaymentSuccessProps> = ({ isTypePc, onBackBillDetail }) => (
  <div className={styles.page}>
    {isTypePc && <Stepper activeStep={3} />}
    <div className={styles.container}>
      <img src={CheckDone} alt="check-done" />
      <p className={styles.title}>支払い完了</p>
      <div className={styles.subTitle}>
        <span >お支払いいただきありがとうございます。</span>
        <span >地域の運営のために大事に使わせていただきます。</span>
      </div>
      <div>
        <SubmitButton
            className={styles.buttonSuccess}
            text="閉じる"
            onClick={() => onBackBillDetail("moveToCompletedPage")}
          />
      </div>
    </div>
  </div>
);
export default PaymentSuccess;
