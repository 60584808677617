import React from 'react';
import styles from './Stepper.module.scss';

export interface StepperProps {
  activeStep: number;
}

export const Stepper: React.FC<StepperProps> = ({ activeStep }) => {
  return (
    <div className={styles.wrapper}>
      <ol className={styles.stepper}>
        <li className={styles.stepper__item}>
          <p className={styles.stepper__number + " " + (activeStep === 1 ? styles.activeCircle : "")}>1</p>
          <p className={styles.stepper__title + " " + (activeStep === 1 ? styles.activeTitle : "")}>カード情報入力</p>
        </li>
        <li className={styles.stepper__item}>
          <p className={styles.stepper__number + " " + (activeStep === 2 ? styles.activeCircle : "")}>2</p>
          <p className={styles.stepper__title + " " + (activeStep === 2 ? styles.activeTitle : "")}>確認</p>
        </li>
        <li className={styles.stepper__item}>
          <p className={styles.stepper__number + " " + (activeStep === 3 ? styles.activeCircle : "")}>3</p>
          <p className={styles.stepper__title + " " + (activeStep === 3 ? styles.activeTitle : "")}>完了</p>
        </li>
      </ol>
    </div>
  );
}

export default Stepper;
