import React from "react";
import styles from "./SubmitButton.module.scss";

export interface SubmitButtonProps {
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  isSubmitting?: boolean;
  text: string;
  className?: string;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  onClick,
  isSubmitting,
  disabled,
  text,
  className,
}) => {
  return (
    <div
      className={
        styles.button +
        " " +
        (disabled || isSubmitting ? styles.invalid : styles.valid) +
        " " +
        (className ? className : "")
      }
      onClick={(e) => !isSubmitting && !disabled && onClick(e)}
    >
      <span>{text}</span>
      {isSubmitting ? (
        <div className={styles.icon}>
          <svg viewBox="0 0 50 50">
            <path d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
              <animateTransform
                attributeType="xml"
                attributeName="transform"
                type="rotate"
                from="0 25 25"
                to="360 25 25"
                dur="0.6s"
                repeatCount="indefinite"
              ></animateTransform>
            </path>
          </svg>
        </div>
      ) : null}
    </div>
  );
};
export default SubmitButton;
