import React from "react";
import BackButton from "../../../svg/keyboard-backspace.svg";
import styles from "./BtnBack.module.scss";

export interface BtnBackProps {
  onClick: () => void;
}

export const BtnBack: React.FC<BtnBackProps> = ({ onClick }) => (
  <img alt="keyboard-backspace" className={styles.btnBack} src={BackButton} width={24} onClick={onClick}/>
);
export default BtnBack;
